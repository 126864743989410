/*!
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

@font-face {
    font-family: "Avenir Next Variable W05";
    src: url("https://fonts.cadonau.net/56b59602-81b2-45db-91e1-da92d39f3ebc.woff2") format("woff2"), url("https://fonts.cadonau.net/2f2e081e-91c9-4952-81fe-c299420d928a.woff") format("woff");
    font-weight: 100 900;
    font-style: normal;
    font-stretch: 75% 100%;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger Variable W05";
    src: url("https://fonts.cadonau.net/d644caa0-1508-40ef-b402-0833a5c76a1b.woff2") format("woff2"), url("https://fonts.cadonau.net/454b61c6-24ff-4956-a340-927727e58968.woff") format("woff");
    font-weight: 100 900;
    font-style: normal;
    font-stretch: 75% 100%;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger Variable W05";
    src: url("https://fonts.cadonau.net/0754785a-60e1-46ac-8e31-d533267870d7.woff2") format("woff2"), url("https://fonts.cadonau.net/b3d180d2-d9af-4742-a632-6a8afcac2e73.woff") format("woff");
    font-weight: 100 900;
    font-style: italic;
    font-stretch: 75% 100%;
    font-display: swap;
}

@font-face {
    font-family: "Fira Code VF";
    src: url("https://fonts.cadonau.net/FiraCode-VF.woff2") format("woff2-variations"), url("https://fonts.cadonau.net/FiraCode-VF.woff") format("woff-variations");
    font-weight: 300 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Egyptienne F W03";
    src: url("https://fonts.cadonau.net/afe3f1cc-2388-46dd-bbb2-8a8276face44.woff2") format("woff2"), url("https://fonts.cadonau.net/67eae0ba-8c20-4b3e-a2f1-c63eb981de61.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next W04";
    src: url("https://fonts.cadonau.net/91e99835-1d11-4c71-af9b-aed2a94329e7.woff2") format("woff2"), url("https://fonts.cadonau.net/4952d2e1-e0c0-46ca-b32e-c24a5882c471.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next W04";
    src: url("https://fonts.cadonau.net/43571193-6de1-4dac-b2a5-e8365cb9dc0e.woff2") format("woff2"), url("https://fonts.cadonau.net/8be8a73f-6804-4351-b8e1-ff88ed20425b.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next W04";
    src: url("https://fonts.cadonau.net/249228f0-61ac-40cc-a5a5-5609c9816e3f.woff2") format("woff2"), url("https://fonts.cadonau.net/efba18ed-80cc-49c4-997a-fbb140739d19.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next W04";
    src: url("https://fonts.cadonau.net/f9210946-ff05-4e2a-b69b-1ee47c087b48.woff2") format("woff2"), url("https://fonts.cadonau.net/88d0c4db-4ea2-4c9c-ad42-a8e0414a57ee.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next W04";
    src: url("https://fonts.cadonau.net/9e20d350-02ed-419e-8306-41e8fddafb4e.woff2") format("woff2"), url("https://fonts.cadonau.net/9ddb7916-058a-4e43-9880-dcb237ef42b6.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next W04";
    src: url("https://fonts.cadonau.net/0511f8d4-c152-4b37-94a3-9723de223de8.woff2") format("woff2"), url("https://fonts.cadonau.net/0ad05680-6397-4845-b42d-0a6564e3f923.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/beadadbd-9f92-47a8-bea5-d47eead87d0f.woff2") format("woff2"), url("https://fonts.cadonau.net/e3a399ff-a932-4dc9-976e-a688554a73fa.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/a80c405a-9ca9-4c5d-a8d3-a85fc6647e40.woff2") format("woff2"), url("https://fonts.cadonau.net/989a89b7-cc47-4968-b6a4-ff2fc4841b45.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/fd6ece76-0770-44d0-8bcb-9bb8eeb7f361.woff2") format("woff2"), url("https://fonts.cadonau.net/18df5c05-bb59-464a-9590-6130c0f7bb94.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/843d846e-1b36-4c16-8e11-11dc76b7cd9c.woff2") format("woff2"), url("https://fonts.cadonau.net/e61c024a-5f43-4d3e-9c86-ae89a1ff6b2c.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/7e36fdf2-8fbb-4663-8f67-a8dafe350758.woff2") format("woff2"), url("https://fonts.cadonau.net/9527dd86-8858-4bb3-9b1f-74fd3a52c9e0.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/8770f324-3051-4460-a0eb-e23e9ad3af95.woff2") format("woff2"), url("https://fonts.cadonau.net/6ab8ea11-b45f-4fc4-818d-182e5bfce3e9.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/2f89542e-56a5-4931-93cf-fca84aa876af.woff2") format("woff2"), url("https://fonts.cadonau.net/47efd999-2385-4127-a10c-adc9c5fb6806.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Frutiger W04";
    src: url("https://fonts.cadonau.net/14412cd2-97db-4efe-99f1-e618bce62a7a.woff2") format("woff2"), url("https://fonts.cadonau.net/0ec5d4dd-621e-4044-9c7e-493b56bac731.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Fira Code";
    src: url("https://fonts.cadonau.net/FiraCode-Light.woff2") format("woff2"), url("https://fonts.cadonau.net/FiraCode-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Fira Code";
    src: url("https://fonts.cadonau.net/FiraCode-Regular.woff2") format("woff2"), url("https://fonts.cadonau.net/FiraCode-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Fira Code";
    src: url("https://fonts.cadonau.net/FiraCode-Medium.woff2") format("woff2"), url("https://fonts.cadonau.net/FiraCode-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Fira Code";
    src: url("https://fonts.cadonau.net/FiraCode-SemiBold.woff2") format("woff2"), url("https://fonts.cadonau.net/FiraCode-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Fira Code";
    src: url("https://fonts.cadonau.net/FiraCode-Bold.woff2") format("woff2"), url("https://fonts.cadonau.net/FiraCode-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
