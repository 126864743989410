@charset "UTF-8";

@mixin responsiveMinWidth($width) {
    @media only all and (min-width: $width) {
        @content;
    }
}

@mixin grid-gap($size) {
    grid-gap: $size;
    gap: $size;
}

@mixin outline {
    outline: 0.125rem solid var(--color-underline-link);
    outline-offset: 0.125rem;
}
