@charset "UTF-8";

@use "sass:map";
@use "sass:meta";
@use "sass:math";
@use "reset.scss";
@use "fonts.scss";
// References to versioned assets
@use "assets.scss";
@use "mixins.scss";
@use "typefaces.scss";
@use "colors.scss";
@use "breakpoints.scss";

:root {
    & {
        --color-scheme: "light";
        @include colors.color-scheme-light;

        // universal
        --color-underline-link: #{colors.$blue};
        --color-underline-visited: #{colors.$purple};
        --color-error: red;
        --color-success: #{colors.$green};
        --color-photo-shadow: #666666; // medium gray
        --color-logo-home: #{colors.$green};
    }

    &[data-user-color-scheme="dark"] {
        --color-scheme: "dark";
        @include colors.color-scheme-dark;
        @include colors.color-scheme-dark-scrollbar;
    }

    &:not([data-user-color-scheme]) {
        @media screen and (prefers-color-scheme: dark) {
            --color-scheme: "dark";
            @include colors.color-scheme-dark;
            @include colors.color-scheme-dark-scrollbar;
        }
    }
}

%heading {
    font-family: typefaces.$font-title;
    line-height: 1.25;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

%h1,
h1{
    @extend %heading;
    font-size: 2rem;
    font-weight: bold; // 700
}

%h2,
h2 {
    @extend %heading;
    font-size: 1.5rem;
    font-weight: bold; // 700
}

%h3,
h3 {
    @extend %heading;
    font-size: 1rem;
    font-weight: bold; // 700
}

%h4,
h4 {
    @extend %heading;
    font-size: 1rem;
    font-weight: 550;
}

%h5,
h5 {
    @extend %heading;
    font-size: 1rem;
    font-weight: 500;
    font-variant: small-caps;
}

a {
    color: var(--color-primary-text);
    text-decoration-color: var(--color-underline-link);

    &:visited {
        text-decoration-color: var(--color-underline-visited);
    }

    text-underline-offset: 0.125em;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 0.1em;

    p > &:hover {
        border-radius: 0.125rem;
        background-color: var(--color-background-link);
        text-decoration-thickness: 0.15em;
    }

    p > &:visited:hover {
        background-color: var(--color-background-visited);
    }

    &:focus {
        @include mixins.outline;
    }
}

p {
    line-height: 1.5;
    margin-bottom: 0.5em;
    font-variant-numeric: oldstyle-nums;
}

strong {
    font-weight: bolder; // cf. https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Meaning_of_relative_weights
}

em {
    font-style: italic;
}

code {
    font-family: typefaces.$font-code;
    font-size: 0.9375rem;
    padding: 0.125rem;
    border-radius: 0.125rem;
    background-color: var(--color-article-separator);
}

pre {
    line-height: 1.5;
    overflow-x: auto;
    padding: 0.5rem;
    background-color: var(--color-article-separator);
}

blockquote {
    font-size: 1.0625rem; // to compensate font-stretch
    font-style: italic;
    font-stretch: semi-condensed;
    padding-left: 0.25rem;
    border: {
        width: 0.25rem;
        color: var(--color-de-emphasis);
        style: none none none solid;
    }
}

hr {
    border: {
        width: 0.0625rem;
        color: var(--color-de-emphasis);
        style: none none solid none;
    }
}

img {
    @media print {
        page-break-inside: avoid;
        break-inside: avoid;
    }
}

button {
    font: inherit;
    margin: 0;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;

    &:focus {
        @include mixins.outline;
    }
}

address {
    font-style: inherit;
}

// Helper
._error {
    color: var(--color-error);
}

// Helper
._hidden {
    visibility: hidden;
}

._visually-hidden {
    position: absolute !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
}

.skip-navigation {
    font-size: 1.5rem;
    position: fixed;
    z-index: 1;
    top: 1rem;
    right: 2%;
    left: 2%;
    padding: 1rem;
    transition: opacity 0.15s ease-in;
    text-align: center;
    opacity: 1;
    color: var(--color-background);
    background: var(--color-background-button);

    &:not(:focus):not(:active) {
        @extend ._visually-hidden;
        opacity: 0;
    }
}

.logo-mc {
    color: var(--color-primary-text);
    fill: var(--color-logo-home);
}

.logo-wordmark {
    fill: var(--color-secondary-text);

    > .markus {
        transform: translateY(0.15em);
    }
}

html {
    font-size: 16px;
    width: 100%; // important for photo detail
    height: 100%; // important for photo detail
    -webkit-text-size-adjust: 100%; // to prevent issues when rotating devices etc.
    @include mixins.responsiveMinWidth(breakpoints.$width-extra-large) {
        font-size: 20px;
    }
}

body {
    font-family: typefaces.$font-body;
    font-size: 1rem;
    color: var(--color-primary-text);
    background-color: var(--color-background);

    font-variant-ligatures: common-ligatures contextual; // ~normal
    font-kerning: normal;
}

.page-template {
    max-width: breakpoints.$width-medium;
    margin: auto;
    padding: 0 2.5%;
    @include mixins.responsiveMinWidth(breakpoints.$width-small) {
        padding: 0 2%;
    }

    > .site-footer {
        clear: both; // floated photos when grid is not supported
    }

    &.-photo-detail {
        width: 100%;
        min-width: breakpoints.$width-micro;
        max-width: breakpoints.$width-large;
        height: 100%;
        padding: initial;
        @media screen {
            color: colors.$primary-text-color_dark-mode;
            background-color: colors.$background-color_dark-mode;
        }
    }

    &.-admin-login,
    &.-admin-overview,
    &.-photo-album,
    &.-blog-post,
    &.-blog-period,
    &.-contact-profile,
    &.-tags-overview,
    &.-tagged-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    pre {
        margin: 0.5rem -0.5rem;
        @include mixins.responsiveMinWidth(breakpoints.$width-medium-large) {
            margin: 1rem -1rem;
            padding: 1rem;
        }
    }

    blockquote {
        @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
            margin-left: -0.5rem;
        }
        @include mixins.responsiveMinWidth(breakpoints.$width-medium-large) {
            margin-left: -1rem;
            padding-left: 0.75rem;
        }
    }
}

%site-header-link-height {
    height: 2rem;
}

%site-header-link-after {
    position: absolute;
    bottom: 0.5rem;
    height: 0.15rem;
    content: "";
    transition: transform 0.2s;
    transform: scaleX(0);
}

.site-header {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    border: {
        width: 0.0625rem;
        color: var(--color-de-emphasis);
        style: none none solid none;
    }
    touch-action: manipulation; // cf. https://webkit.org/blog/5610/more-responsive-tapping-on-ios/

    &.-with-logo {
        > .site-logo {
            margin-right: 0.5rem;
        }

        > .main-menu {
            display: contents;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini) {
                display: initial;
                float: right;
            }
        }

        > .main-menu > .list {
            display: contents;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini) {
                display: initial;
            }
        }

        > .main-menu > .list > .item.-home {
            display: none;
            @include mixins.responsiveMinWidth(breakpoints.$width-small) {
                display: inline-block;
            }
        }

        > .main-menu > .list > .item > .link {
            @extend %site-header-link-height;
        }
    }
}

.site-logo {
    & {
        position: relative;
        display: inline-block;
        padding: 1rem 0;
        text-decoration: none;
    }

    > .logo-mc,
    > .logo-wordmark {
        width: auto;
        max-height: 100%;
    }

    &.-menu-link {
        @extend %site-header-link-height;

        > .logo-wordmark {
            display: none;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
                display: inherit;
                margin-left: 0.75rem;
            }
        }

        &::after {
            @extend %site-header-link-after;
            right: 0;
            width: 3.2rem;
            background-color: colors.$green;
            @include mixins.responsiveMinWidth(breakpoints.$width-small) {
                display: none;
            }
        }

        &:hover::after,
        &[aria-current="page"]::after {
            transform: scaleX(1);
        }
    }
}

.main-menu {

    > .list {
        @include mixins.responsiveMinWidth(breakpoints.$width-mini) {
            text-align: center;
        }
    }

    > .list > .item {
        font-family: typefaces.$font-title;
        font-weight: bold; // 700
        display: inline-block;

        &:not(:last-child) {
            margin-right: 0.5rem;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
                margin-right: 0.75rem;
            }
            @include mixins.responsiveMinWidth(breakpoints.$width-small) {
                margin-right: 1rem;
            }
        }
    }

    > .list > .item > .link {
        position: relative;
        display: inline-block;
        padding: 1rem 0;
        text-decoration-line: none;
        // -offset (0.3em), -thickness (0.15em), -skip (edges) are unfortunately not supported well enough
    }

    > .list > .item > .link:focus {
        outline-offset: 0.25rem;
    }

    > .list > .item > .link::before {
        display: block;
        width: 1rem;
        height: 1rem;
        margin-right: auto;
        margin-left: auto;
        content: "";
        transition: filter 0.2s;
        filter: brightness(var(--icon-filter-brightness)); // cf. https://css-tricks.com/solved-with-css-colorizing-svg-backgrounds/
    }

    > .list > .item > .link:hover::before,
    > .list > .item > .link[aria-current="page"]::before {
        filter: brightness(var(--icon-active-filter-brightness));
    }

    > .list > .item > .link::after {
        @extend %site-header-link-after;
        display: block;
        width: 100%;
    }

    > .list > .item > .link:hover::after,
    > .list > .item > .link[aria-current="page"]::after {
        transform: scaleX(1);
    }

    > .list > .item > .link.-home::before {
        $home-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#home-view";
        background: url($home-icon) no-repeat;
    }

    > .list > .item > .link.-home::after {
        background-color: colors.$green;
    }

    > .list > .item > .link.-photos::before {
        $image-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#image-view";
        background: url($image-icon) no-repeat;
    }

    > .list > .item > .link.-photos::after {
        background-color: colors.$yellow;
    }

    > .list > .item > .link.-blog::before {
        $content-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#content-view";
        background: url($content-icon) no-repeat;
    }

    > .list > .item > .link.-blog::after {
        background-color: colors.$orange;
    }

    > .list > .item > .link.-about::before {
        $user-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#user-view";
        background: url($user-icon) no-repeat;
    }

    > .list > .item > .link.-about::after {
        background-color: colors.$red;
    }
}

.more-content {
    & {
        font-family: typefaces.$font-title;
        font-weight: bold; // 700
        margin: 2rem 0;
    }

    &.-feed {
        margin: 1rem 0;
    }

    & > .pagination {
        display: flex;
        justify-content: space-between;
        touch-action: manipulation; // cf. https://webkit.org/blog/5610/more-responsive-tapping-on-ios/
    }

    & > .pagination > .link {
        text-decoration: none;
    }

    & > .pagination > .link::before,
    & > .pagination > .link::after {
        position: relative;
        bottom: 0.0625em;
        display: inline-block;
        width: 1em;
        height: 1em;
        content: "";
        vertical-align: middle;
        filter: brightness(colors.$brightness-standard_light-mode);
    }

    & > .pagination > .link.-prev::before {
        $caret-left-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#caret-left-view";
        background: url($caret-left-icon) no-repeat;
    }

    & > .pagination > .link.-next::after {
        $caret-right-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#caret-right-view";
        background: url($caret-right-icon) no-repeat;
    }
}

.home-page {
    > .homepage-intro {
        margin-top: 2rem;
        margin-bottom: 0.875rem; // align following content with blog page
    }
}

.blog-page {
    > .header {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.about-page {
    @include mixins.responsiveMinWidth(breakpoints.$width-small-medium) {
        display: flex;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    > .portrait {
        margin-top: 1rem;
        @include mixins.responsiveMinWidth(breakpoints.$width-small-medium) {
            flex: calc(100% / 3);
            margin-top: 6rem;
            margin-right: 2rem;
        }
    }

    > .portrait > .caption {
        font-size: 0.875rem;
        margin-top: 0.5rem;
    }

    > .profile {
        @include mixins.responsiveMinWidth(breakpoints.$width-small-medium) {
            flex: calc(100% / 3 * 2);
        }
    }

    > .profile > .title > .permalink {
        display: none;
    }
}

.tag-page {
    > .title {
        font-family: typefaces.$font-tag;
        font-weight: 400;
        font-style: italic;

        &::before {
            content: "#";
        }
    }
}

.admin-page,
.album-page,
.blog-page,
.about-page,
.tags-page,
.tag-page {
    flex: 1 0 auto;
}

.homepage-intro {
    & {
        font-size: 0.875rem;
        font-style: italic;
    }

    > .text {
        display: inline;
    }

    > .text > [rel="author"] {
        text-decoration-line: none;
    }

    > .text > [rel="author"]:hover {
        text-decoration-line: underline;
    }

    > .title {
        font-family: inherit;
        font-size: inherit;
        display: inline;
    }
}

.feed-link {
    text-decoration: none;

    > .icon {
        width: 1rem;
        height: 1rem;
        margin: 0 0.125rem;
        vertical-align: bottom;
        border-radius: 0.125rem;
    }

    > .icon.-atom {
        background-color: colors.$orange;
        fill: white;
    }
}

%date {
    font-size: 0.75rem;
    font-weight: bold; // 700
    color: var(--color-secondary-text);
}

.album-section,
.blog-article {
    & {
        padding: 1rem 0;
    }

    & + .album-section, & + .blog-article {
        border-top: 0.03125rem solid var(--color-article-separator);
    }

    &.-single {
        h1 {
            @extend %h2;
        }

        h2 {
            @extend %h3;
        }

        h3 {
            @extend %h4;
        }

        h4 {
            @extend %h5;
        }
    }

    > .header {
        margin: 1rem 0 1.5rem 0;
    }

    > .footer {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-top: 0.25rem;
    }

    > .footer > .date {
        @extend %date;
    }

    > .title > .permalink {
        text-decoration: none;
    }

    > .footer > .permalink,
    > .footer > .address > [rel="author"] {
        @extend %date;
        text-decoration-line: none;
    }

    > .footer > .permalink:hover {
        text-decoration-line: underline;
        text-decoration-color: var(--color-secondary-text);
    }

    > .footer > .permalink:hover::after,
    > .title > .permalink:hover::after {
        display: inline-block;
        width: 0.75em;
        height: 0.75em;
        margin-left: 0.33333333em;
        content: "";
        $link-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#link-view";
        background: url($link-icon) no-repeat;
        filter: brightness(var(--icon-in-secondary-text-filter-brightness));
    }

    > .content {
        line-height: 1.5;

        &.-album {
            $column-gap: 1rem;
            columns: math.div((32rem * 0.96 - $column-gap), 2) 2; // 14.86rem
            column-gap: $column-gap;
        }
    }

    ul, ol {
        line-height: 1.5;
        margin-bottom: 0.5rem;
    }

    ul > li::before {
        // workaround for different browser behaviors re list-style-[…] and ::marker
        // otherwise preferable: list-style-type: disc and list-style-position: outside
        font-size: 0.875rem;
        display: inline-block;
        width: 0.5rem;
        margin-left: -0.5rem;
        content: "•";
        @include mixins.responsiveMinWidth(breakpoints.$width-medium-large) {
            width: 1rem;
            margin-left: -1rem;
        }
    }

    ol {
        list-style-position: inside;
        list-style-type: decimal;
        @include mixins.responsiveMinWidth(breakpoints.$width-medium-large) {
            list-style-position: outside;
        }
    }

    video {
        max-width: 100%;
        height: auto;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    > .tags {
        margin: 1.5rem 0 0 0;
    }
}

.album-section img {
    border-radius: 1%;
}

.tag-list {
    > .tag {
        display: inline;
        margin-right: 0.25rem;
    }

    > .tag::before {
        display: none;
    }

    > .tag > [rel="tag"] {
        font-family: typefaces.$font-tag;
        font-style: italic;
        text-decoration: none;
    }

    > .tag > [rel="tag"]::before {
        content: "#";
        color: var(--color-underline-link);
    }

    > .tag > [rel="tag"]:visited::before {
        color: var(--color-underline-visited);
    }
}

.all-tags-list {
    column-width: 8rem;
    column-gap: 1rem;

    > .tag {
        overflow: hidden;
        padding: 0.5rem 0;
        text-overflow: ellipsis;
    }
}

.tagged-items {
    > .item {
        line-height: 1.5;
        padding: 0.5rem 0;
        @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
            display: flex;
        }
    }

    > .item > .link {
        flex: 0 0 auto; // so ::after ":" doesn't wrap
    }

    > .item > .link::after {
        display: inline-block; // to make text-decoration:none work
        content: ":";
        text-decoration: none;
    }

    > .item > .link > .date {
        font-weight: bold; // 700
    }

    > .item > .teaser {
        margin-left: 0.5rem;
        color: var(--color-secondary-text);
    }

    > .item > .teaser > .title {
        font-weight: bold; // 700
        color: var(--color-primary-text);
    }
}

.archive-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));

    > .year {
        float: left; // if container not displayed as grid
        width: 7rem;
        margin-bottom: 1rem;
        @supports (display: grid) {
            width: auto;
        }
    }

    > .year > .months > .month {
        padding: 0.25rem 0;
    }
}

.contact-options {
    margin: 1.25rem 0;

    > .option {
        margin: 1rem 0;
    }

    > .option > .link {
        padding: 0.5rem 0;
        text-decoration: none;
    }

    > .option > .link > .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        vertical-align: middle;
    }

    > .option > .link > .identifier {
        font-weight: bold; // 700
    }
}

.microblog-icon {
    transition: fill 0.2s;
    fill: var(--color-de-emphasis);

    &.-color {
        fill: #FD9927;
    }
}

.mail-icon {
    transition: fill 0.2s;
    fill: var(--color-de-emphasis);
}

.site-footer {
    font-size: 0.875rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
    border: {
        width: 0.0625rem;
        color: var(--color-de-emphasis);
        style: solid none none none;
    }
    @supports (padding: max(0px)) {
        //noinspection CssInvalidFunction
        padding-bottom: max(0.75rem, env(safe-area-inset-bottom));
    }

    > .color-scheme-toggle {
        display: inline-block;
    }

    > .totop {
        display: inline-block;
        padding: 0.5rem 0;
    }
}

.color-scheme-toggle {
    > .switch {
        transition: color 0.2s ease, background 0.2s ease-in-out;
        color: var(--color-background);
        background: var(--color-background-button);
    }

    > .switch:hover {
        background: var(--color-primary-text);
    }
}

@include meta.load-css("photos.scss");
