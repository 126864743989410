@charset "UTF-8";

@use "sass:color";

// Colors
$background-color_light-mode: white;
$primary-text-color_light-mode: black;
$background-color_dark-mode: #212121;
$primary-text-color_dark-mode: #ECECEC;

$icon-color_light-mode: #A6A6A6; // cf. SVG; without brightness filter etc.
$wordmark-color_light-mode: #767676;

$scrollbar-track-color_dark-mode: #2E2E2E; // same as Safari default
$scrollbar-border-color_dark-mode: #484848; // same as Safari default

$brightness-standard_light-mode: 1;
$brightness-highlight_light-mode: 0; // results in black
$brightness-standard_dark-mode: 0.71;
$brightness-highlight_dark-mode: 1.42;

$green: #61BB46;
$yellow: #FDB827;
$orange: #F5821F;
$red: #E03A3E;
$purple: #963D97;
$blue: #009DDC;

@mixin color-scheme-light {
    --color-primary-text: #{$primary-text-color_light-mode};
    --color-background: #{$background-color_light-mode};
    --color-de-emphasis: #{$icon-color_light-mode};
    --color-secondary-text: #{$wordmark-color_light-mode};
    --icon-filter-brightness: #{$brightness-standard_light-mode};
    --icon-active-filter-brightness: #{$brightness-highlight_light-mode};
    --icon-in-secondary-text-filter-brightness: #{$brightness-standard_dark-mode};
    --color-article-separator: #{color.adjust($background-color_light-mode, $red: -44, $green: -44, $blue: -44)};
    --color-background-link: #{color.adjust($blue, $lightness: 50%)};
    --color-background-visited: #{color.adjust($purple, $lightness: 50%)};
    --color-background-button: #{color.adjust($background-color_dark-mode, $red: 44, $green: 44, $blue: 44)};
}

@mixin color-scheme-dark {
    --color-primary-text: #{$primary-text-color_dark-mode};
    --color-background: #{$background-color_dark-mode};
    --color-de-emphasis: #{$wordmark-color_light-mode};
    --color-secondary-text: #{$icon-color_light-mode};
    --icon-filter-brightness: #{$brightness-standard_dark-mode};
    --icon-active-filter-brightness: #{$brightness-highlight_dark-mode};
    --icon-in-secondary-text-filter-brightness: #{$brightness-standard_light-mode};
    --color-article-separator: #{color.adjust($background-color_dark-mode, $red: 44, $green: 44, $blue: 44)};
    --color-background-link: #{color.adjust($blue, $lightness: -25%)};
    --color-background-visited: #{color.adjust($purple, $lightness: -20%)};
    --color-background-button: #{color.adjust($background-color_light-mode, $red: -44, $green: -44, $blue: -44)};
}

@mixin color-scheme-dark-scrollbar {
    scrollbar-color: #{color.scale($icon-color_light-mode, $lightness: ($brightness-standard_dark-mode - 1) * 100%)} $scrollbar-track-color_dark-mode; // Firefox

    ::-webkit-scrollbar {
        width: 0.875rem;
    }

    ::-webkit-scrollbar-track {
        background: $scrollbar-track-color_dark-mode;
        border-left: 0.03125rem solid $scrollbar-border-color_dark-mode;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #{color.scale($icon-color_light-mode, $lightness: ($brightness-standard_dark-mode - 1) * 100%)};
        border-radius: 0.5rem;
        border: 0.25rem solid transparent;
        background-clip: content-box;

        &:hover, &:active {
            background-color: $icon-color_light-mode;
        }
    }
}
