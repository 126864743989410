@charset "UTF-8";

@use "sass:color";

@use "sass:map";
@use "mixins.scss";
@use "typefaces.scss";
@use "colors.scss";
@use "breakpoints.scss";
@use "assets";

.photo-albums {

    $gap: 1rem;

    & {
        display: grid;
        @include mixins.grid-gap($gap);
        @include mixins.responsiveMinWidth(breakpoints.$width-small) {
            grid-template-columns: 1fr 1fr;
        }
        // Revert grid layout when printing,
        // since CSS Fragmentation is not (yet) well supported with grid.
        @media print {
            display: block;
        }
    }

    > .album {
        position: relative;
        float: left; // if container not displayed as grid
        width: 100%;
        max-width: 640px;
        margin-top: 1rem;
        @include mixins.responsiveMinWidth(breakpoints.$width-small) {
            width: calc((100% - #{$gap}) / 2);
            margin-right: $gap;
            margin-bottom: $gap;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        // Progressively apply grid layout
        // by correcting values needed when not displayed as grid.
        // Only on screens, not when printing,
        // since CSS Fragmentation is not (yet) well supported with grid.
        @media screen {
            @supports (display: grid) {
                width: auto;
                margin-right: initial;
                margin-bottom: initial;
            }
        }

        @media print {
            page-break-inside: avoid;
            break-inside: avoid;
        }
    }

    > .album > .title {
        font-size: 1rem;
        line-height: inherit;
        position: absolute;
        display: inline-block;
        max-width: calc(100% - 2rem);
        margin: 0;
        @include mixins.responsiveMinWidth(breakpoints.$width-medium-large) {
            max-width: calc(100% - 5rem);
        }
    }

    > .album > .title > .link {
        display: inline-block;
        overflow: hidden;
        max-width: 100%;
        height: 1.25rem;
        white-space: nowrap;
        text-decoration: none;
        text-overflow: ellipsis;
    }

    > .album > .count {
        font-family: typefaces.$font-body;
        font-size: 0.8rem;
        font-weight: 800;
        position: absolute;
        right: 0;
        display: inline-block;
        color: var(--color-secondary-text);
    }

    > .album > .count > .label {
        display: none;
        @include mixins.responsiveMinWidth(breakpoints.$width-medium) {
            display: initial;
        }
    }

    > .album > .imagecontainerlink {
        display: block;
        margin-top: 2rem;
    }

    > .album > .imagecontainerlink > .picture > .image {
        width: 100%;
        height: 100%;
        transition-duration: 0.25s;
        transition-property: box-shadow;
        border-radius: 1%;

        &:hover {
            box-shadow: 0.2rem 0.2rem 0.4rem var(--color-photo-shadow);
        }

        &:active {
            position: relative; //needed for top/left when active/pressed (button effect)
            top: 0.2rem;
            left: 0.2rem;
            box-shadow: 0.05rem 0.05rem 0.1rem var(--color-photo-shadow);
        }
    }
}

.album-page {
    > .description {
        margin-bottom: 1.5rem;
        @include mixins.responsiveMinWidth(breakpoints.$width-medium) {
            margin-bottom: 3rem;
        }
    }
}

.album-index {

    $thumbnail-margin-small: 1%;
    $thumbnail-margin-medium: 1.75rem;
    $thumbnail-margin-extra-large: calc((#{breakpoints.$width-medium} - 4 * 200px) / 3);

    & {
        display: grid;
        clear: both;
        @include mixins.grid-gap(1vw);
        grid-template-columns: repeat(4, auto);
        @include mixins.responsiveMinWidth(breakpoints.$width-small-medium) {
            grid-template-columns: repeat(5, auto);
        }
        @include mixins.responsiveMinWidth(breakpoints.$width-medium) {
            justify-content: center;
            @include mixins.grid-gap($thumbnail-margin-medium);
            grid-template-columns: repeat(4, minmax(150px, 200px));
            justify-items: center;
        }
        @include mixins.responsiveMinWidth(breakpoints.$width-extra-large) {
            @include mixins.grid-gap($thumbnail-margin-extra-large);
        }
        // Revert grid layout when printing,
        // since CSS Fragmentation is not (yet) well supported with grid.
        @media print {
            display: block;
        }
    }

    > .thumbnail {
        position: relative;
        display: inline-block;
        float: left; // if container not displayed as grid
        width: calc((100% - 3 * #{$thumbnail-margin-small}) / 4);
        max-width: 200px;
        max-height: 200px;
        margin-right: $thumbnail-margin-small;
        margin-bottom: $thumbnail-margin-small;
        &:nth-child(4n) {
            margin-right: 0;
        }
        @media only all and (min-width: breakpoints.$width-small-medium) and (max-width: breakpoints.$width-medium) {
            width: calc((100% - 4 * #{$thumbnail-margin-small}) / 5);
            &:nth-child(4n) {
                margin-right: $thumbnail-margin-small;
            }
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
        @include mixins.responsiveMinWidth(breakpoints.$width-medium) {
            width: calc((100% - 3 * #{$thumbnail-margin-medium}) / 4);
            margin-right: $thumbnail-margin-medium;
            margin-bottom: $thumbnail-margin-medium;
        }
        @include mixins.responsiveMinWidth(breakpoints.$width-extra-large) {
            width: 200px;
            margin-right: $thumbnail-margin-extra-large;
            margin-bottom: $thumbnail-margin-extra-large;
        }
        // Progressively apply grid layout
        // by correcting values needed when not displayed as grid.
        // Only on screens, not when printing,
        // since CSS Fragmentation is not (yet) well supported with grid.
        @media screen {
            @supports (display: grid) {
                width: 100%;
                margin: initial;
            }
        }

        @media print {
            page-break-inside: avoid;
            break-inside: avoid;
        }
    }

    > .thumbnail::before {
        /* Workaround to define aspect ratio (square) */
        display: inline-block;
        width: 0;
        height: 0;
        padding-bottom: 100%;
        content: "";
    }

    > .thumbnail > .squarecontainer {
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    > .thumbnail > .squarecontainer > .link {
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    > .thumbnail > .squarecontainer > .link > .image {
        max-width: 100%;
        max-height: 100%;
        border-radius: 1%;
        object-fit: cover;
        object-position: center center;
        @include mixins.responsiveMinWidth(breakpoints.$width-medium) {
            object-fit: contain;
        }
    }
}

@mixin transform($transformation) {
    -webkit-transform: $transformation;
    -moz-transform: $transformation;
    -ms-transform: $transformation;
    transform: $transformation;
}

.photo-page {

    & {
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        touch-action: manipulation;
    }

    > .header {
        height: 4rem;
        @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
            height: 2rem;
        }
    }

    > .header > .navigation > .list {
        display: flex;
        flex-wrap: wrap;
        @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
            flex-wrap: nowrap;
        }
    }

    > .header > .navigation > .list > .item {

        &.index {
            flex: 50%;
            order: 1;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
                flex: 0 1 9rem;
            }
            @media print {
                visibility: hidden;
            }
        }

        &.current {
            flex: 50%;
            order: 2;
            text-align: right;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
                flex: 0 1 calc(100% - 18rem);
                text-align: center;
            }
        }

        &.prev {
            flex: auto;
            order: 3;
            text-align: left;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
                flex: 1 0 auto;
                text-align: right;
            }
            @media print {
                visibility: hidden;
            }
        }

        &.divider {
            display: none;
            order: 4;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
                display: initial;
                padding: 0.5rem 0.25rem;
            }
            @media print {
                visibility: hidden;
            }
        }

        &.next {
            flex: auto;
            order: 5;
            text-align: right;
            @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
                flex: 0 0 auto;
            }
            @media print {
                visibility: hidden;
            }
        }
    }

    %icon {
        position: relative;
        bottom: 0.0625em;
        display: inline-block;
        width: 1em;
        height: 1em;
        content: "";
        vertical-align: middle;
        filter: brightness(colors.$brightness-standard_light-mode);
    }

    > .header > .navigation > .list > .item > .link {

        & {
            display: inline-block;
            padding: 0.5rem 0;
            text-decoration: none;
            @media screen {
                color: colors.$primary-text-color_dark-mode;
            }
        }

        &:focus {
            outline: 0.0625rem solid var(--color-underline-link);
            outline-offset: -0.0625rem;
        }

        &#index::before {
            @extend %icon;
            $arrow-left-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#arrow-left-view";
            background: url($arrow-left-icon) no-repeat;
        }

        &#prev::before {
            @extend %icon;
            $caret-left-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#caret-left-view";
            background: url($caret-left-icon) no-repeat;
        }

        &#next::after {
            @extend %icon;
            $caret-right-icon: map.get(assets.$assets, "/media/icon-sprite.svg") + "#caret-right-view";
            background: url($caret-right-icon) no-repeat;
        }
    }

    > .container {
        display: flex;
        align-items: center;
        width: calc(100% * var(--count));
        height: calc(100% - 4rem);
        --position: 1;
        --count: 1;
        --offset: 0px;
        //noinspection CssInvalidFunction
        @include transform(translateX(calc((var(--position, 1) - 1) / var(--count) * -100% + var(--offset, 0px))));
        @include mixins.responsiveMinWidth(breakpoints.$width-mini-small) {
            height: calc(100% - 2rem);
        }

        @media (prefers-reduced-motion: reduce) {
            //noinspection CssInvalidFunction
            @include transform(translateX(calc((var(--position, 1) - 1) / var(--count) * -100%)));
            transition: none !important;
        }

        &:focus {
            outline: none;
        }

        &:focus::after {
            position: absolute;
            left: calc((var(--position, 1) - 1) / var(--count) * 100%);
            width: calc(100% / var(--count));
            height: 100%;
            content: "";
            outline: 0.125rem solid var(--color-underline-link);
            outline-offset: -0.125rem;
        }
    }

    > .container > .photo {
        width: calc(100% / var(--count));
        height: 100%;
        max-height: breakpoints.$width-large;
    }

    > .container > .photo > .caption {
        overflow: hidden;
        height: 1.5rem;
        padding: 0.25rem 0;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    > .container > .photo > .image {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    > .container > .photo > .caption + .image {
        max-height: calc(100% - 2rem);
    }

    > .instructions {
        position: absolute;
        bottom: 0;
        display: none;
        padding: 0.5rem;
        border-radius: 0.3125rem;
        background-color: color.adjust(colors.$background-color_dark-mode, $alpha: -0.5);
        -webkit-backdrop-filter: blur(0.625rem);
        backdrop-filter: blur(0.625rem);
    }

    @media screen and (hover: hover) {
        > .container:hover + .instructions {
            display: block;
        }
    }

    > .container:focus + .instructions {
        display: block;
    }
}
