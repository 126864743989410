@charset "UTF-8";

// Breakpoints
$width-micro: 8.5em; // 136px – smaller Apple Watch
$width-micro-mini: 9.75em; // 156px – bigger Apple Watch
$width-mini: 15em;
$width-mini-small: 20em;
$width-small: 30em;
$width-small-medium: 37.5em;
$width-medium: 48em;
$width-medium-large: 60em;
$width-large: 64em;
$width-extra-large: 80em;
$width-maximum: 120em;
